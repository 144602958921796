import { BaseImageProps, styled } from "@outschool/backpack";
import NextImage from "next/image";
import React from "react";

import type { ImageProps as BaseNextImageProps } from "next/image";

export type NextImage = Omit<BaseNextImageProps, "src"> & {
  src?: BaseNextImageProps["src"];
};

/**
 * Using this type with `BaseImageProps` adds the `nextProps` attribute, allowing
 * you to use `next/image` specific props.
 *
 * All `nextImage` will override their base prop counterparts
 * e.g.
 * ```{ src: "foo.jpg", nextImage: { src: "bar.jpg" } }``` will result in
 * the `src` prop being `"bar.jpg"`
 */
export type WithNextImageProp = {
  nextImage?: NextImage;
};
export type SsrImageProps = BaseImageProps<WithNextImageProp>;

const StyledNextImage = styled(NextImage)(
  {}
) as React.ComponentType<BaseNextImageProps>;

/**
 * An SSR-specific implementation of `Image` that is used automatically by Backpack.
 * @deprecated DO NOT use directly. Use Image from `@outschool/backpack` instead.
 */
const SsrImage = ({ src, nextImage, onLoad, ...props }: SsrImageProps) => (
  // @ts-expect-error NextImage props don't jive with BaseImageProps, but they work just fine
  <StyledNextImage
    {...props}
    {...nextImage}
    src={nextImage?.src ?? src}
    onLoadingComplete={onLoad as () => void | undefined}
  />
);

export default SsrImage as React.ExoticComponent<SsrImageProps>;
